import React, {
    Fragment,
    useState,
    useCallback,
  } from 'react'  
import update from 'immutability-helper'
import { navigate } from "gatsby"
import {
    Page,
    Layout,
    Card,
    EmptyState,
    Stack,
  } from "@shopify/polaris"
function PostPurchaseInstruction(props) {
    const { token, shop, location, firebase } = props
    const existingOffer = location && location.state && location.state.rule
    console.log("existingOffer", existingOffer)
    return (
        <Page 
            breadcrumbs={[{content: 'Offers', url: '/app/offers/createOfferType'}]}
            title={
                `Create New Offer`
            }
        >
        <Layout>
            <Layout.Section>
                <Card>
                <Card.Section>
                <Stack distribution="fill">
                    <EmptyState
                        heading="Let’s activate UpsellPlus Post Purchase Offers on your store "
                        action={{
                            content: 'I did it! Let’s configure an offer',
                            onAction: () => navigate('/app/offers/createPostPurchase', {replace: true})
                        }}
                        image="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/create-offer.svg?alt=media&token=b18f4972-21bc-4eb7-8a06-4040e86f9d47"
                        fullWidth={true}
                    >
                    <div style={{width: '50%', margin: 'auto', textAlign:'left'}}>
                        <p>
                            1. On your Shopify store, go to Settings
                        </p>
                        <p>
                            2. Click ‘Checkout’
                        </p>
                        <p>
                            3. Scroll down to ‘Post Purchase page’
                        </p>
                        <p>
                            4. Choose ‘UpsellPlus’
                        </p>
                    </div>
                    </EmptyState>
                    <div style={{width: '60%', margin: 'auto', marginTop: '-6rem', fontSize: '10px'}}>
                        <span>Note: Post-purchase offers add secondary payments to customers' orders. When using the manual payment capture method you may need to capture them separately.</span>
                    </div>
                </Stack>
                </Card.Section>
                </Card>
            </Layout.Section>
        </Layout>
    </Page>
    )
}

export default PostPurchaseInstruction;